import React from "react"
import { StaticImage } from "gatsby-plugin-image"


export default function Test() {
  return (
    <div className="container mx-auto">
      <div className="md:flex md:space-x-6">
        <figure className="relative overflow-hidden group w-1/3">
          <StaticImage src="../media/aurland/IMG_0099.jpg" aspectRatio={12/15} className="group-hover:transform group-hover:scale-105 duration-300 ease-in-out" alt="Aurland" />
          <figcaption className="absolute -mt-48 font-heading text-6xl text-white px-6 w-full" >
            Flåm<br/>
            Aurland
          </figcaption>
        </figure>
        <figure className="relative overflow-hidden group w-1/3">
          <StaticImage src="../media/lofoten/IMG_0201.jpg" aspectRatio={12/15} className="group-hover:transform group-hover:scale-105 duration-300 ease-in-out object-fill" alt="Lofoten" />
          <figcaption className="absolute -mt-48 font-heading text-6xl text-white px-6 w-full" >
            Flåm<br/>
            Aurland
          </figcaption>
        </figure>
        <figure className="relative overflow-hidden group w-1/3">
          <StaticImage src="../media/lofoten/90a5771f-9563-4fd9-9274-63e3786935f2.jpg" aspectRatio={12/15} className="group-hover:transform group-hover:scale-105 duration-300 ease-in-out" alt="Lofoten" />
          <figcaption className="absolute -mt-48 font-heading text-6xl text-white px-6 w-full" >
            Flåm<br/>
            Aurland
          </figcaption>
        </figure>
      </div>
    </div>
  )
}
